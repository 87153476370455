var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "3xl"
    }
  }, [_vm._v(" Detail Glossary ")]), _vm.glossary ? _c('c-button', {
    attrs: {
      "type": "button",
      "variant": "solid",
      "variant-color": "red",
      "rounded": "full"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.isDeleting = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg')
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" Hapus ")])], 1) : _vm._e(), _vm.glossary ? _c('c-button', {
    attrs: {
      "type": "button",
      "variant": "solid",
      "variant-color": "primary",
      "color": "white",
      "rounded": "full",
      "ml": "10px",
      "as": "router-link",
      "to": {
        name: 'superadmin.glossary-edit',
        params: {
          glossaryId: _vm.glossary.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg')
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" Edit ")])], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "w": "full",
      "mt": "30px"
    }
  }, _vm._l(_vm.dataGlossary, function (item, idx) {
    return _c('c-box', {
      key: item.label
    }, [!item.isGambar ? _c('Detail', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "label": item.label,
        "value": item.value,
        "mt": idx === 0 ? 0 : '10px'
      }
    }) : _vm._e(), item.value != null && item.isGambar ? _c('c-box', {
      attrs: {
        "mt": idx === 0 ? 0 : '10px'
      }
    }, [_c('c-text', {
      attrs: {
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('img', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "src": item.value,
        "alt": item.label,
        "h": "230px",
        "w": "230px",
        "rounded": "lg",
        "object-fit": "contain"
      }
    })], 1) : _vm._e()], 1);
  }), 1), _vm.isDeleting ? _c('Portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('DeleteConfirm', {
    attrs: {
      "is-open": _vm.isDeleting,
      "content": "Anda yakin ingin menghapus glossary ini?"
    },
    on: {
      "on-delete": function onDelete($event) {
        return _vm.deleteGlossary($event);
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }