var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-text', {
    attrs: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatNullOrEmpty")(_vm._f("formatArray")(_vm._f("formatBoolean")(_vm.value)))) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }